import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServerService {
  
  url = "https://iconsfashionwll.com/admin/api/";
  // url = "https://galopmasters.com/admin/api/";
  userdata:any;


  constructor(private http: HttpClient) { }

  homepage(id)
  {
  	return this.http.get(this.url+'homepage?lid='+localStorage.getItem('lid')+"&user_id="+localStorage.getItem('user_id')+"&city_id="+localStorage.getItem('city_id')+"&cate_id="+id+'&cart_no='+localStorage.getItem('cart_no'))
  	    	   .pipe(map(results => results));
  }

  item(id)
  {
    return this.http.get(this.url+'item?lid='+localStorage.getItem('lid')+"&user_id="+localStorage.getItem('user_id')+"&store_id="+id)
             .pipe(map(results => results));
  }
  
  itemDetails(id)
  {
    return this.http.get(this.url+'itemDetails?lid='+localStorage.getItem('lid')+"&user_id="+localStorage.getItem('user_id')+"&store_id="+id)
             .pipe(map(results => results));
  }
  city()
  {
  	return this.http.get(this.url+'city?lid='+localStorage.getItem('lid'))
  	    	   .pipe(map(results => results));
  }

  page()
  {
    return this.http.get(this.url+'page?lid='+localStorage.getItem('lid'))
             .pipe(map(results => results));
  }

  
  makeStripePayment(data)
  {
    return this.http.get(this.url+'makeStripePayment'+data+'&lid='+localStorage.getItem('lid'))
             .pipe(map(results => results));
  }

  userInfo(id)
  {
    return this.http.get(this.url+'userInfo?id='+id+'&lid='+localStorage.getItem('lid'))
             .pipe(map(results => results));
  }

  my(id)
  {
    return this.http.get(this.url+'my?id='+id+'&lid='+localStorage.getItem('lid'))
             .pipe(map(results => results));
  }

  getLang(id)
  {
    return this.http.get(this.url+'getLang?lang_id='+id)
             .pipe(map(results => results));
  }

  addToCart(data)
  {
    return this.http.post(this.url+'addToCart?lang_id='+localStorage.getItem('lid'),data)
             .pipe(map(results => results));
  }

  cartCount(id)
  {
    return this.http.get(this.url+'cartCount?lid='+localStorage.getItem('lid')+"&cart_no="+id)
             .pipe(map(results => results));
  }

  updateCart(id,type)
  {
    return this.http.get(this.url+'updateCart/'+id+'/'+type)
             .pipe(map(results => results));
  }

  getCart(cartNo)
  {
    return this.http.get(this.url+'getCart/'+cartNo+'?lid='+localStorage.getItem('lid'))
             .pipe(map(results => results));
  }

  getOffer(cartNo)
  {
    var phone=0;
    if(localStorage.getItem('user_data') && localStorage.getItem('user_data') != undefined)
      {
        this.userdata = JSON.parse(localStorage.getItem('user_data'));
        phone = this.userdata.phone;
      }


    return this.http.get(this.url+'getOffer/'+cartNo+'?lid='+localStorage.getItem('lid')+"&phone="+phone)
             .pipe(map(results => results));
  }

  applyCoupen(id,cartNo)
  {
    return this.http.get(this.url+'applyCoupen/'+id+'/'+cartNo)
             .pipe(map(results => results));
  }

  order(data)
  {
    return this.http.post(this.url+'order',data)
    .pipe(map(results => results));
  }
}