import { Component, OnInit } from "@angular/core";

import { Platform, NavController } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { HttpClient, HttpErrorResponse } from "@angular/common/http"; // Add this import

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  currentVersion = '1.0.6';
  public selectedIndex = 0;
  public appPages: any;

  userData: any;
  setting: any;
  lang_data: any;
  dir = 'ltr';
  text: any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private nav: NavController,
    private http: HttpClient // Add this injection
  ) {
    this.initializeApp();

    if (
      localStorage.getItem("lang_data") &&
      localStorage.getItem("lang_data") != "null"
    ) {
      localStorage.setItem("city_id", "1");
      if (
        localStorage.getItem("city_id") &&
        localStorage.getItem("city_id") != "null"
      ) {
        this.nav.navigateRoot("/home");
      } else {
        this.nav.navigateRoot("/city");
      }
    } else {
      this.nav.navigateRoot("/lang");
    }

    /*
     ********************************************
     **Setup language
     ********************************************
     */
    if (
      localStorage.getItem("lang_data") &&
      localStorage.getItem("lang_data") != undefined
    ) {
      this.lang_data = JSON.parse(localStorage.getItem("lang_data"));

      this.dir = this.lang_data.type == "1" ? "rtl" : "ltr";
    }

    if (
      localStorage.getItem("app_text") &&
      localStorage.getItem("app_text") != undefined
    ) {
      this.text = JSON.parse(localStorage.getItem("app_text"));

      this.appPages = [
        {
          title: this.text.menu_home,
          url: "/home",
          icon: "home",
        },

        {
          title: this.text.menu_my_account,
          url: "/account",
          icon: "person-circle",
        },

        {
          title: this.text.menu_lang,
          url: "/lang",
          icon: "flag",
        },



        {
          title: this.text.menu_about,
          url: "/about",
          icon: "information-circle",
        },

        {
          title: this.text.menu_faq,
          url: "/faq",
          icon: "create",
        },

        {
          title: this.text.menu_contact,
          url: "/contact",
          icon: "mail",
        },
      ];
    }
  }

  initializeApp() {
    this.splashScreen.show();
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString("#127f7b");
      this.statusBar.styleLightContent();

      this.sub();
      this.checkVersion(); // Add this line to check the version
    });
  }
  async checkVersion() {
    try {
      const response = await this.http.get('./assets/version.json').toPromise();
      console.log('Full response:', response);
  
      if (response && typeof response === 'object' && 'version' in response) {
        const serverVersion = (response as { version: string }).version;
        console.log('Server version:', serverVersion);
        console.log('Current version:', this.currentVersion);
  
        if (serverVersion !== this.currentVersion) {
          console.log('New version available. Updating...');
          window.location.reload(true);
        } else {
          console.log('App is up to date.');
        }
      } else {
        console.error('Invalid version file format:', response);
      }
    } catch (error) {
      console.error('Failed to check version:', error);
      if (error instanceof HttpErrorResponse) {
        console.error('Status:', error.status);
        console.error('Status Text:', error.statusText);
        console.error('URL:', error.url);
      }
    }
  }

  sub() {
    // this.oneSignal.startInit(
    //   this.setting.push_app_id,
    //   this.setting.push_google
    // );

    // this.oneSignal.inFocusDisplaying(
    //   this.oneSignal.OSInFocusDisplayOption.InAppAlert
    // );

    // this.oneSignal.handleNotificationReceived().subscribe(() => {
    //   // do something when notification is received
    // });

    // this.oneSignal.handleNotificationOpened().subscribe(() => {
    //   // do something when a notification is opened
    // });

    // if (
    //   localStorage.getItem("user_data") &&
    //   localStorage.getItem("user_data") != undefined
    // ) {
    //   this.oneSignal.sendTags({ user_id: this.userData.phone });
    // }

    // this.oneSignal.endInit();
  }

  ngOnInit() {
    if (
      localStorage.getItem("user_data") &&
      localStorage.getItem("user_data") != undefined &&
      localStorage.getItem("user_data") != "null"
    ) {
      this.userData = JSON.parse(localStorage.getItem("user_data"));
    }

    this.setting = JSON.parse(localStorage.getItem("setting"));

    const path = window.location.pathname.split("folder/")[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(
        (page) => page.title.toLowerCase() === path.toLowerCase()
      );
    }
  }
}
